import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @alignItems='flex-start'\n  @direction='column'\n  {{style alignSelf='flex-start'}}\n>\n  {{#each (array 0 1 2 3 4 5 6 7 8 9 10 11 12 13)}}\n    <AkSkeleton\n      class='mt-2 ml-2'\n      @width='600px'\n      @height='19px'\n      data-test-component-list-skeleton-loader\n    />\n  {{/each}}\n</AkStack>", {"contents":"<AkStack\n  @alignItems='flex-start'\n  @direction='column'\n  {{style alignSelf='flex-start'}}\n>\n  {{#each (array 0 1 2 3 4 5 6 7 8 9 10 11 12 13)}}\n    <AkSkeleton\n      class='mt-2 ml-2'\n      @width='600px'\n      @height='19px'\n      data-test-component-list-skeleton-loader\n    />\n  {{/each}}\n</AkStack>","moduleName":"irene/components/sbom/scan-details/skeleton-loader-list/index.hbs","parseOptions":{"srcName":"irene/components/sbom/scan-details/skeleton-loader-list/index.hbs"}});
import Component from '@glimmer/component';

export default class SbomScanDetailsSkeletonLoaderListComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::ScanDetails::SkeletonLoaderList': typeof SbomScanDetailsSkeletonLoaderListComponent;
  }
}
