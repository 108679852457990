import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography\n  data-test-storeknoxInventoryDetails-unscannedVersionTable-dateFound\n>\n  {{this.dateFound}}\n</AkTypography>", {"contents":"<AkTypography\n  data-test-storeknoxInventoryDetails-unscannedVersionTable-dateFound\n>\n  {{this.dateFound}}\n</AkTypography>","moduleName":"irene/components/storeknox/inventory-details/unscanned-version/table/date-found/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/unscanned-version/table/date-found/index.hbs"}});
import Component from '@glimmer/component';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import type SkAppVersionModel from 'irene/models/sk-app-version';

dayjs.extend(advancedFormat);

interface StoreknoxInventoryDetailsUnscannedVersionTableDateFoundSignature {
  Args: {
    skAppVersion: SkAppVersionModel;
  };
}

export default class StoreknoxInventoryDetailsUnscannedVersionTableDateFoundComponent extends Component<StoreknoxInventoryDetailsUnscannedVersionTableDateFoundSignature> {
  get dateFound() {
    return dayjs(this.args.skAppVersion.get('createdOn')).format('Do MMM YYYY');
  }
}
