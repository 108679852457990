import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack local-class='project-card' @alignItems='center'>\n  <AppPlatformIcon @platform='{{@project.platformIcon}}' />\n\n  <AkTypography>\n    {{@project.packageName}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack local-class='project-card' @alignItems='center'>\n  <AppPlatformIcon @platform='{{@project.platformIcon}}' />\n\n  <AkTypography>\n    {{@project.packageName}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/partner/client-project-detail/index.hbs","parseOptions":{"srcName":"irene/components/partner/client-project-detail/index.hbs"}});
import Component from '@glimmer/component';
import type PartnerPartnerclientProjectModel from 'irene/models/partner/partnerclient-project';

export interface PartnerClientProjectDetailComponentSignature {
  Args: {
    project: PartnerPartnerclientProjectModel;
  };
}

export default class PartnerClientProjectDetailComponent extends Component<PartnerClientProjectDetailComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Partner::ClientProjectDetail': typeof PartnerClientProjectDetailComponent;
  }
}
