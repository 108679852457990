import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkSkeleton @width='250px' @height='14px' class='mt-2 mb-4' />\n\n<div local-class='summary-header-root' class='mb-3'>\n  <AkStack\n    @alignItems='center'\n    @justifyContent='space-between'\n    @spacing='1.5'\n    local-class='summary-header-container'\n  >\n    <AkSkeleton @width='150px' @height='18px' />\n  </AkStack>\n</div>\n\n<Sbom::EmptyLoadingView\n  @empty={{false}}\n  @loading={{true}}\n  @loadingSvgHeight='200px'\n  @height='500px'\n/>", {"contents":"<AkSkeleton @width='250px' @height='14px' class='mt-2 mb-4' />\n\n<div local-class='summary-header-root' class='mb-3'>\n  <AkStack\n    @alignItems='center'\n    @justifyContent='space-between'\n    @spacing='1.5'\n    local-class='summary-header-container'\n  >\n    <AkSkeleton @width='150px' @height='18px' />\n  </AkStack>\n</div>\n\n<Sbom::EmptyLoadingView\n  @empty={{false}}\n  @loading={{true}}\n  @loadingSvgHeight='200px'\n  @height='500px'\n/>","moduleName":"irene/components/sbom/component-details/skeleton-loader/index.hbs","parseOptions":{"srcName":"irene/components/sbom/component-details/skeleton-loader/index.hbs"}});
import Component from '@glimmer/component';

export default class SbomComponentDetailsSkeletonLoaderComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::ComponentDetails::SkeletonLoader': typeof SbomComponentDetailsSkeletonLoaderComponent;
  }
}
