import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='org-settings-container'>\n  {{#if this.me.org.is_owner}}\n    <RegulatoryPreferenceOrganization />\n\n    <AkDivider class='my-3' @color='dark' />\n\n    <OrganizationMfa\n      @organization={{@model.organization}}\n      @user={{@model.user}}\n    />\n\n    <AkDivider class='my-3' @color='dark' />\n  {{/if}}\n\n  {{#if @model.organization.features.sso}}\n    <SsoSettings @organization={{@model.organization}} @user={{@model.user}} />\n\n    <AkDivider class='my-3' @color='dark' />\n  {{/if}}\n\n  <OrganizationEmailDomain @isEditable={{this.me.org.is_owner}} />\n\n  <AkDivider class='my-3' @color='dark' />\n\n  <OrganizationArchive />\n\n  <AkDivider class='my-3' @color='dark' />\n\n  <FileCleanup />\n</div>", {"contents":"<div local-class='org-settings-container'>\n  {{#if this.me.org.is_owner}}\n    <RegulatoryPreferenceOrganization />\n\n    <AkDivider class='my-3' @color='dark' />\n\n    <OrganizationMfa\n      @organization={{@model.organization}}\n      @user={{@model.user}}\n    />\n\n    <AkDivider class='my-3' @color='dark' />\n  {{/if}}\n\n  {{#if @model.organization.features.sso}}\n    <SsoSettings @organization={{@model.organization}} @user={{@model.user}} />\n\n    <AkDivider class='my-3' @color='dark' />\n  {{/if}}\n\n  <OrganizationEmailDomain @isEditable={{this.me.org.is_owner}} />\n\n  <AkDivider class='my-3' @color='dark' />\n\n  <OrganizationArchive />\n\n  <AkDivider class='my-3' @color='dark' />\n\n  <FileCleanup />\n</div>","moduleName":"irene/components/organization/settings/index.hbs","parseOptions":{"srcName":"irene/components/organization/settings/index.hbs"}});
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type MeService from 'irene/services/me';
import type { OrganizationSettingsRouteModel } from 'irene/routes/authenticated/dashboard/organization-settings/index';

export interface OrganizationSettingsSignature {
  Args: {
    model: OrganizationSettingsRouteModel;
  };
}

export default class OrganizationSettingsComponent extends Component<OrganizationSettingsSignature> {
  @service declare me: MeService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Organization::Settings': typeof OrganizationSettingsComponent;
  }
}
