import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='0.5'>\n  <AkTypography\n    @color='textSecondary'\n    @variant='body2'\n    data-test-prjSettings-integrations-jiraProject-noProject\n  >\n    {{#if @reconnect}}\n      {{t 'jira'}}\n      {{t 'reconnectGotoSettings'}}\n    {{else}}\n      {{t 'jiraNoProject'}}\n    {{/if}}\n  </AkTypography>\n\n  <AkLink\n    @typographyVariant='body2'\n    @route='authenticated.dashboard.organization-settings.integrations'\n    @color='primary'\n    data-test-prjSettings-integrations-jiraProject-noProject-integrationLink\n  >\n    {{t 'clickingHere'}}\n  </AkLink>\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='0.5'>\n  <AkTypography\n    @color='textSecondary'\n    @variant='body2'\n    data-test-prjSettings-integrations-jiraProject-noProject\n  >\n    {{#if @reconnect}}\n      {{t 'jira'}}\n      {{t 'reconnectGotoSettings'}}\n    {{else}}\n      {{t 'jiraNoProject'}}\n    {{/if}}\n  </AkTypography>\n\n  <AkLink\n    @typographyVariant='body2'\n    @route='authenticated.dashboard.organization-settings.integrations'\n    @color='primary'\n    data-test-prjSettings-integrations-jiraProject-noProject-integrationLink\n  >\n    {{t 'clickingHere'}}\n  </AkLink>\n</AkStack>","moduleName":"irene/components/project-settings/integrations/jira-project/no-project/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/integrations/jira-project/no-project/index.hbs"}});
import Component from '@glimmer/component';

export interface ProjectSettingsIntegrationsJiraProjectNoProjectSignature {
  Args: {
    reconnect: boolean;
  };
}

export default class ProjectSettingsIntegrationsJiraProjectNoProjectComponent extends Component<ProjectSettingsIntegrationsJiraProjectNoProjectSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProjectSettings::Integrations::JiraProject::NoProject': typeof ProjectSettingsIntegrationsJiraProjectNoProjectComponent;
  }
}
