import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @direction='column'\n  class='mb-3 py-3'\n  local-class='prj-settings-integrations-root'\n>\n  <AkStack @width='full' class='px-3'>\n    <ProjectSettings::Integrations::JiraProject @project={{this.project}} />\n  </AkStack>\n\n  <AkDivider @color='dark' class='my-3' />\n\n  <AkStack @width='full' class='px-3'>\n    <ProjectSettings::Integrations::GithubProject @project={{this.project}} />\n  </AkStack>\n\n  <AkDivider @color='dark' class='my-3' />\n\n  <AkStack @width='full' class='px-3'>\n    <ProjectSettings::Integrations::ServiceNow @project={{this.project}} />\n  </AkStack>\n</AkStack>", {"contents":"<AkStack\n  @direction='column'\n  class='mb-3 py-3'\n  local-class='prj-settings-integrations-root'\n>\n  <AkStack @width='full' class='px-3'>\n    <ProjectSettings::Integrations::JiraProject @project={{this.project}} />\n  </AkStack>\n\n  <AkDivider @color='dark' class='my-3' />\n\n  <AkStack @width='full' class='px-3'>\n    <ProjectSettings::Integrations::GithubProject @project={{this.project}} />\n  </AkStack>\n\n  <AkDivider @color='dark' class='my-3' />\n\n  <AkStack @width='full' class='px-3'>\n    <ProjectSettings::Integrations::ServiceNow @project={{this.project}} />\n  </AkStack>\n</AkStack>","moduleName":"irene/components/project-settings/integrations/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/integrations/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { waitForPromise } from '@ember/test-waiters';
import type Store from '@ember-data/store';

import type ProjectModel from 'irene/models/project';
import type ProfileModel from 'irene/models/profile';
import type MeService from 'irene/services/me';

interface ProjectSettingsIntegrationsSignature {
  Args: {
    project: ProjectModel | null;
  };
}

export default class ProjectSettingsIntegrationsComponent extends Component<ProjectSettingsIntegrationsSignature> {
  @service declare me: MeService;
  @service declare store: Store;

  @tracked profile: ProfileModel | null = null;

  constructor(
    owner: unknown,
    args: ProjectSettingsIntegrationsSignature['Args']
  ) {
    super(owner, args);

    this.fetchProfile.perform();
  }

  get project() {
    return this.args.project;
  }

  fetchProfile = task(async () => {
    try {
      const profileId = this.args.project?.activeProfileId;

      this.profile = await waitForPromise(
        this.store.findRecord('profile', String(profileId))
      );
    } catch (e) {
      this.profile = null;

      return;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProjectSettings::Integrations': typeof ProjectSettingsIntegrationsComponent;
  }
}
