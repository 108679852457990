import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @justifyContent='center' @spacing='1'>\n  <AkTypography @fontWeight='medium'>{{t 'action'}}</AkTypography>\n\n  <AkTooltip @color='dark' @placement='top-start' @arrow={{true}}>\n    <:tooltipContent>\n      <div local-class='tooltip-content'>\n        <AkTypography @color='inherit'>\n          {{t 'storeknox.actionsInfo'}}\n        </AkTypography>\n      </div>\n    </:tooltipContent>\n\n    <:default>\n      <AkIcon @iconName='info' @size='small' />\n    </:default>\n  </AkTooltip>\n</AkStack>", {"contents":"<AkStack @alignItems='center' @justifyContent='center' @spacing='1'>\n  <AkTypography @fontWeight='medium'>{{t 'action'}}</AkTypography>\n\n  <AkTooltip @color='dark' @placement='top-start' @arrow={{true}}>\n    <:tooltipContent>\n      <div local-class='tooltip-content'>\n        <AkTypography @color='inherit'>\n          {{t 'storeknox.actionsInfo'}}\n        </AkTypography>\n      </div>\n    </:tooltipContent>\n\n    <:default>\n      <AkIcon @iconName='info' @size='small' />\n    </:default>\n  </AkTooltip>\n</AkStack>","moduleName":"irene/components/storeknox/inventory-details/unscanned-version/table/actions-header/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/unscanned-version/table/actions-header/index.hbs"}});
import Component from '@glimmer/component';
import type SkAppVersionModel from 'irene/models/sk-app-version';

interface StoreknoxInventoryDetailsUnscannedVersionTableActionsHeaderSignature {
  Args: {
    skAppVersion: SkAppVersionModel;
  };
}

export default class StoreknoxInventoryDetailsUnscannedVersionTableActionsHeaderComponent extends Component<StoreknoxInventoryDetailsUnscannedVersionTableActionsHeaderSignature> {}
