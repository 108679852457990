import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @direction='column'\n  @spacing='4'\n  @alignItems='center'\n  @width='full'\n  local-class='feature-unavailable-root'\n  {{style padding='4.2857em 1.5em'}}\n  data-test-storeknoxInventoryDetails-featureUnavailable-root\n>\n  {{yield to='illustration'}}\n\n  <AkStack\n    @direction='column'\n    @alignItems='center'\n    @spacing='0.5'\n    {{style maxWidth='485px'}}\n  >\n    <AkTypography\n      @variant='h5'\n      @align='center'\n      data-test-storeknoxInventoryDetails-featureUnavailable-headerTitle\n    >\n      {{@headerTitle}}\n    </AkTypography>\n\n    <AkTypography\n      @align='center'\n      data-test-storeknoxInventoryDetails-featureUnavailable-headerDescription\n    >\n      {{@headerDescription}}\n    </AkTypography>\n  </AkStack>\n</AkStack>", {"contents":"<AkStack\n  @direction='column'\n  @spacing='4'\n  @alignItems='center'\n  @width='full'\n  local-class='feature-unavailable-root'\n  {{style padding='4.2857em 1.5em'}}\n  data-test-storeknoxInventoryDetails-featureUnavailable-root\n>\n  {{yield to='illustration'}}\n\n  <AkStack\n    @direction='column'\n    @alignItems='center'\n    @spacing='0.5'\n    {{style maxWidth='485px'}}\n  >\n    <AkTypography\n      @variant='h5'\n      @align='center'\n      data-test-storeknoxInventoryDetails-featureUnavailable-headerTitle\n    >\n      {{@headerTitle}}\n    </AkTypography>\n\n    <AkTypography\n      @align='center'\n      data-test-storeknoxInventoryDetails-featureUnavailable-headerDescription\n    >\n      {{@headerDescription}}\n    </AkTypography>\n  </AkStack>\n</AkStack>","moduleName":"irene/components/storeknox/inventory-details/feature-unavailable/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/feature-unavailable/index.hbs"}});
import Component from '@glimmer/component';

interface StoreknoxInventoryDetailsFeatureUnavailableSignature {
  Args: {
    headerTitle: string;
    headerDescription: string;
  };
  Blocks: { illustration: [] };
}

export default class StoreknoxInventoryDetailsFeatureUnavailableComponent extends Component<StoreknoxInventoryDetailsFeatureUnavailableSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::FeatureUnavailable': typeof StoreknoxInventoryDetailsFeatureUnavailableComponent;
  }
}
