import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n  data-test-radio-root\n  local-class='ak-radio-root {{unless @disabled \"ak-radio-hover-focus\"}}'\n>\n  <input\n    data-test-radio\n    ...attributes\n    local-class='ak-radio'\n    checked={{this.checked}}\n    disabled={{@disabled}}\n    readonly={{@readonly}}\n    value={{@value}}\n    name={{this.name}}\n    type='radio'\n    id={{this.id}}\n    {{on 'change' this.onChange}}\n  />\n\n  {{#if this.checked}}\n    <AkIcon\n      data-test-radio-checked\n      local-class='ak-svg-radio-icon ak-svg-radio-icon-checked'\n      @iconName='mdi:radio-button-checked'\n    />\n  {{else}}\n    <AkIcon\n      data-test-radio-unchecked\n      local-class='ak-svg-radio-icon'\n      @iconName='mdi:radio-button-unchecked'\n    />\n  {{/if}}\n</span>", {"contents":"<span\n  data-test-radio-root\n  local-class='ak-radio-root {{unless @disabled \"ak-radio-hover-focus\"}}'\n>\n  <input\n    data-test-radio\n    ...attributes\n    local-class='ak-radio'\n    checked={{this.checked}}\n    disabled={{@disabled}}\n    readonly={{@readonly}}\n    value={{@value}}\n    name={{this.name}}\n    type='radio'\n    id={{this.id}}\n    {{on 'change' this.onChange}}\n  />\n\n  {{#if this.checked}}\n    <AkIcon\n      data-test-radio-checked\n      local-class='ak-svg-radio-icon ak-svg-radio-icon-checked'\n      @iconName='mdi:radio-button-checked'\n    />\n  {{else}}\n    <AkIcon\n      data-test-radio-unchecked\n      local-class='ak-svg-radio-icon'\n      @iconName='mdi:radio-button-unchecked'\n    />\n  {{/if}}\n</span>","moduleName":"irene/components/ak-radio/index.hbs","parseOptions":{"srcName":"irene/components/ak-radio/index.hbs"}});
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';

interface AkRadioSignature {
  Args: {
    id?: string;
    name?: string;
    checked?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    value?: string | number;
    radioCtx?: {
      name: string;
      value: string | number;
      handleChange: (event: Event) => void;
    };
    onChange?: (event: Event, checked: boolean) => void;
  };
  Element: HTMLInputElement;
}

export default class AkRadioComponent extends Component<AkRadioSignature> {
  get id() {
    return this.args.id || `ak-radio-${guidFor(this)}`;
  }

  get name() {
    // while in group name is required for onchange to properly work
    if (this.args.radioCtx) {
      return this.args.radioCtx.name;
    }

    return this.args.name || '';
  }

  get checked() {
    if (!this.isEmpty(this.args.checked)) {
      return this.args.checked;
    }

    return (
      !this.isEmpty(this.args.value) &&
      this.areEqualValues(this.args.value, this.args.radioCtx?.value)
    );
  }

  get isUncontrolled() {
    return this.isEmpty(this.args.checked);
  }

  isEmpty(value: unknown) {
    return (
      value === null ||
      value === undefined ||
      (typeof value === 'string' && !value.trim())
    );
  }

  areEqualValues(a: unknown, b: unknown) {
    if (typeof b === 'object' && b !== null) {
      return a === b;
    }

    // The value could be a number, the DOM will stringify it anyway.
    return String(a) === String(b);
  }

  @action
  onChange(event: Event) {
    this.args.radioCtx?.handleChange(event);

    if (this.args.onChange) {
      this.args.onChange(event, (event.target as HTMLInputElement).checked);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkRadio: typeof AkRadioComponent;
  }
}
