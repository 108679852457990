import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='py-1' data-test-sbomComponentDetails-container>\n  <AkStack\n    data-test-sbomComponentDetails-sbomHeader\n    local-class='sbom-component-details-root'\n    class='p-2'\n  >\n    <AkTypography\n      data-test-sbomComponentDetails-headerTitleValue\n      @variant='h5'\n      local-class='sbom-component-details-value'\n    >\n      {{this.componentName}}\n    </AkTypography>\n  </AkStack>\n\n  <AkTabs class='mt-3' as |Akt|>\n    {{#each this.tabs as |item|}}\n      <Akt.tabItem\n        @id={{item.id}}\n        @route={{item.route}}\n        @currentWhen={{item.activeRoutes}}\n        data-test-sbomComponentDetails-tab='{{item.id}}'\n      >\n        {{item.label}}\n      </Akt.tabItem>\n    {{/each}}\n  </AkTabs>\n</div>", {"contents":"<div class='py-1' data-test-sbomComponentDetails-container>\n  <AkStack\n    data-test-sbomComponentDetails-sbomHeader\n    local-class='sbom-component-details-root'\n    class='p-2'\n  >\n    <AkTypography\n      data-test-sbomComponentDetails-headerTitleValue\n      @variant='h5'\n      local-class='sbom-component-details-value'\n    >\n      {{this.componentName}}\n    </AkTypography>\n  </AkStack>\n\n  <AkTabs class='mt-3' as |Akt|>\n    {{#each this.tabs as |item|}}\n      <Akt.tabItem\n        @id={{item.id}}\n        @route={{item.route}}\n        @currentWhen={{item.activeRoutes}}\n        data-test-sbomComponentDetails-tab='{{item.id}}'\n      >\n        {{item.label}}\n      </Akt.tabItem>\n    {{/each}}\n  </AkTabs>\n</div>","moduleName":"irene/components/sbom/component-details/index.hbs","parseOptions":{"srcName":"irene/components/sbom/component-details/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

import type SbomComponentModel from 'irene/models/sbom-component';

export interface SbomComponentDetailsSignature {
  Args: {
    sbomComponent: SbomComponentModel | null;
  };
  Blocks: {
    default: [];
  };
}

export default class SbomComponentDetailsComponent extends Component<SbomComponentDetailsSignature> {
  @service declare intl: IntlService;

  get sbomFile() {
    return this.args.sbomComponent?.sbFile;
  }

  get sbomProject() {
    return this.sbomFile?.get('sbProject');
  }

  get componentName() {
    const bomRef = this.args.sbomComponent?.bomRef;

    const truncatedBomRef = bomRef?.substring(0, bomRef.lastIndexOf(':'));
    return `${truncatedBomRef}  :  ${this.args.sbomComponent?.name}`;
  }

  get tabs() {
    return [
      {
        id: 'overview',
        label: this.intl.t('overview'),
        route: 'authenticated.dashboard.sbom.component-details.overview',
        activeRoutes: 'authenticated.dashboard.sbom.component-details.overview',
      },
      {
        id: 'vulnerabilities',
        label: this.intl.t('vulnerabilities'),
        route: 'authenticated.dashboard.sbom.component-details.vulnerabilities',
        activeRoutes:
          'authenticated.dashboard.sbom.component-details.vulnerabilities',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::ComponentDetails': typeof SbomComponentDetailsComponent;
  }
}
