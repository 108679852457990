import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Storeknox::InventoryDetails::FeatureUnavailable\n  @headerTitle={{t 'storeknox.brandAbuseFeatureUnavailableHeader'}}\n  @headerDescription={{t 'storeknox.brandAbuseFeatureUnavailableSubText'}}\n>\n  <:illustration>\n    <AkSvg::SoxBrandAbuseFeatureAbsence\n      data-test-storeknoxInventoryDetails-brandAbuseFeatureUnavailable-Illustration\n    />\n  </:illustration>\n</Storeknox::InventoryDetails::FeatureUnavailable>", {"contents":"<Storeknox::InventoryDetails::FeatureUnavailable\n  @headerTitle={{t 'storeknox.brandAbuseFeatureUnavailableHeader'}}\n  @headerDescription={{t 'storeknox.brandAbuseFeatureUnavailableSubText'}}\n>\n  <:illustration>\n    <AkSvg::SoxBrandAbuseFeatureAbsence\n      data-test-storeknoxInventoryDetails-brandAbuseFeatureUnavailable-Illustration\n    />\n  </:illustration>\n</Storeknox::InventoryDetails::FeatureUnavailable>","moduleName":"irene/components/storeknox/inventory-details/brand-abuse/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/brand-abuse/index.hbs"}});
import Component from '@glimmer/component';

export default class StoreknoxInventoryDetailsBrandAbuseComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::BrandAbuse': typeof StoreknoxInventoryDetailsBrandAbuseComponent;
  }
}
