export default {
  "expand-icon": "_expand-icon_124tkd",
  "main-tree": "_main-tree_124tkd",
  "tree-action-container": "_tree-action-container_124tkd",
  "tree-label": "_tree-label_124tkd",
  "tree-label-highlighted-text": "_tree-label-highlighted-text_124tkd",
  "tree-node": "_tree-node_124tkd",
  "last-node": "_last-node_124tkd",
  "tree-connectors": "_tree-connectors_124tkd",
  "space": "_space_124tkd",
  "margin": "_margin_124tkd",
  "tree-package-name-container": "_tree-package-name-container_124tkd",
  "tree-connector-parent-line": "_tree-connector-parent-line_124tkd",
  "component-tree-container": "_component-tree-container_124tkd",
  "tree-label-highlighted": "_tree-label-highlighted_124tkd",
  "highlighted-return-icon-container": "_highlighted-return-icon-container_124tkd",
  "highlighted-return-icon": "_highlighted-return-icon_124tkd"
};
