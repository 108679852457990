import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header local-class='notification_header'>\n  <div local-class='notification_header_line'>\n    <div local-class='notification_title_area'>\n      <AkTypography @variant='h4'>\n        <span local-class='notification_title'>{{t 'notifications'}}</span>\n      </AkTypography>\n\n      <AkTypography\n        @variant='h5'\n        local-class='notifications_unread_count'\n        data-test-unread-count\n      >\n        {{this.unReadCount}}\n      </AkTypography>\n    </div>\n\n    <AkButton\n      @type='button'\n      @color='primary'\n      @variant='text'\n      @underline='always'\n      {{on 'click' this.markAllAsRead}}\n      data-test-ak-button-mark-all-as-read\n    >\n      <strong>Mark all as read</strong>\n    </AkButton>\n  </div>\n</header>", {"contents":"<header local-class='notification_header'>\n  <div local-class='notification_header_line'>\n    <div local-class='notification_title_area'>\n      <AkTypography @variant='h4'>\n        <span local-class='notification_title'>{{t 'notifications'}}</span>\n      </AkTypography>\n\n      <AkTypography\n        @variant='h5'\n        local-class='notifications_unread_count'\n        data-test-unread-count\n      >\n        {{this.unReadCount}}\n      </AkTypography>\n    </div>\n\n    <AkButton\n      @type='button'\n      @color='primary'\n      @variant='text'\n      @underline='always'\n      {{on 'click' this.markAllAsRead}}\n      data-test-ak-button-mark-all-as-read\n    >\n      <strong>Mark all as read</strong>\n    </AkButton>\n  </div>\n</header>","moduleName":"irene/components/notifications-dropdown/header/index.hbs","parseOptions":{"srcName":"irene/components/notifications-dropdown/header/index.hbs"}});
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';

import type AkNotificationsService from 'irene/services/ak-notifications';

export default class NotificationsDropdownHeaderComponent extends Component {
  @service declare akNotifications: AkNotificationsService;

  get unReadCount() {
    return this.akNotifications.unReadCount;
  }

  @action
  onShowUnReadOnlyChange() {
    this.akNotifications.refresh.perform();
  }

  @action
  markAllAsRead() {
    this.akNotifications.markAllAsRead.perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NotificationsDropdown::Header': typeof NotificationsDropdownHeaderComponent;
  }
}
