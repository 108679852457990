import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @sbomFile.isPending}}\n  -\n{{else}}\n  <AkChip\n    @variant='semi-filled'\n    @label={{or @sbomFile.statusValue (t 'chipStatus.neverInitiated')}}\n    @size='small'\n    @color={{@sbomFile.statusColor}}\n    data-test-sbom-scanStatus\n  />\n{{/if}}", {"contents":"{{#if @sbomFile.isPending}}\n  -\n{{else}}\n  <AkChip\n    @variant='semi-filled'\n    @label={{or @sbomFile.statusValue (t 'chipStatus.neverInitiated')}}\n    @size='small'\n    @color={{@sbomFile.statusColor}}\n    data-test-sbom-scanStatus\n  />\n{{/if}}","moduleName":"irene/components/sbom/scan-status/index.hbs","parseOptions":{"srcName":"irene/components/sbom/scan-status/index.hbs"}});
import Component from '@glimmer/component';

import SbomFileModel from 'irene/models/sbom-file';

export interface SbomScanStatusSignature {
  Args: {
    sbomFile: SbomFileModel;
  };
}

export default class SbomScanStatusComponent extends Component<SbomScanStatusSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::ScanStatus': typeof SbomScanStatusComponent;
    'sbom/scan-status': typeof SbomScanStatusComponent;
  }
}
