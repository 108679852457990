import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showChip}}\n  <AkChip\n    ...attributes\n    @size='small'\n    @variant='semi-filled'\n    @color={{@condition}}\n    @label={{@label}}\n    local-class='status'\n  />\n{{/if}}", {"contents":"{{#if this.showChip}}\n  <AkChip\n    ...attributes\n    @size='small'\n    @variant='semi-filled'\n    @color={{@condition}}\n    @label={{@label}}\n    local-class='status'\n  />\n{{/if}}","moduleName":"irene/components/storeknox/inventory-details/unscanned-version/status/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/unscanned-version/status/index.hbs"}});
import Component from '@glimmer/component';
import type { AkChipColor } from 'irene/components/ak-chip';

interface StoreknoxInventoryDetailsUnscannedVersionStatusSignature {
  Element: HTMLDivElement;
  Args: {
    condition?: AkChipColor;
    label?: string;
  };
}

export default class StoreknoxInventoryDetailsUnscannedVersionStatusComponent extends Component<StoreknoxInventoryDetailsUnscannedVersionStatusSignature> {
  get showChip() {
    return this.args.condition && this.args.label;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::UnscannedVersion::Status': typeof StoreknoxInventoryDetailsUnscannedVersionStatusComponent;
  }
}
