import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @direction='column' @spacing='2' class='mt-1'>\n  <AkSkeleton @width='250px' @height='16px' />\n\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkSkeleton @width='32px' @height='13px' />\n\n    <AkSkeleton @width='150px' @height='15px' />\n  </AkStack>\n</AkStack>", {"contents":"<AkStack @direction='column' @spacing='2' class='mt-1'>\n  <AkSkeleton @width='250px' @height='16px' />\n\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkSkeleton @width='32px' @height='13px' />\n\n    <AkSkeleton @width='150px' @height='15px' />\n  </AkStack>\n</AkStack>","moduleName":"irene/components/project-settings/integrations/loading-ui/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/integrations/loading-ui/index.hbs"}});
import Component from '@glimmer/component';

export default class ProjectSettingsIntegrationsLoadingUiComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProjectSettings::Integrations::LoadingUi': typeof ProjectSettingsIntegrationsLoadingUiComponent;
  }
}
