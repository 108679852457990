import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='org-integration-settings-integrations-container'>\n  <Organization::Integrations::JiraAccount @user={{@model.user}} />\n\n  <AkDivider class='my-3' @color='dark' />\n\n  <Organization::Integrations::GithubAccount\n    @integratedUser={{@model.integratedUser}}\n    @reconnect={{@model.reconnect}}\n    @user={{@model.user}}\n  />\n\n  <AkDivider class='my-3' @color='dark' />\n\n  <Organization::Integrations::ServiceNow @user={{@model.user}} />\n</div>", {"contents":"<div local-class='org-integration-settings-integrations-container'>\n  <Organization::Integrations::JiraAccount @user={{@model.user}} />\n\n  <AkDivider class='my-3' @color='dark' />\n\n  <Organization::Integrations::GithubAccount\n    @integratedUser={{@model.integratedUser}}\n    @reconnect={{@model.reconnect}}\n    @user={{@model.user}}\n  />\n\n  <AkDivider class='my-3' @color='dark' />\n\n  <Organization::Integrations::ServiceNow @user={{@model.user}} />\n</div>","moduleName":"irene/components/organization/integrations/index.hbs","parseOptions":{"srcName":"irene/components/organization/integrations/index.hbs"}});
import Component from '@glimmer/component';
import type { OrganizationSettingsIntegrationsRouteModel } from 'irene/routes/authenticated/dashboard/organization-settings/integrations';

export interface OrganizationIntegrationsSignature {
  Args: {
    model: OrganizationSettingsIntegrationsRouteModel;
  };
}

export default class OrganizationIntegrationsComponent extends Component<OrganizationIntegrationsSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Organization::Integrations': typeof OrganizationIntegrationsComponent;
  }
}
