import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkLink\n  @color='primary'\n  @route='authenticated.dashboard.organization.namespaces'\n  @underline='always'\n  @fontWeight='bold'\n  target='_blank'\n  rel='noopener noreferrer'\n  {{style textTransform='capitalize'}}\n  data-test-storeknoxInventoryDetails-viewNamespacesLink\n  ...attributes\n>\n  <:leftIcon>\n    <AkIcon\n      @color='primary'\n      @iconName='open-in-new'\n      {{style fontSize='1.5714rem !important'}}\n      data-test-storeknoxInventoryDetails-viewNamespacesLinkOpenInNewIcon\n    />\n  </:leftIcon>\n\n  <:default>\n    {{t 'notificationModule.viewNamespaces'}}\n  </:default>\n</AkLink>", {"contents":"<AkLink\n  @color='primary'\n  @route='authenticated.dashboard.organization.namespaces'\n  @underline='always'\n  @fontWeight='bold'\n  target='_blank'\n  rel='noopener noreferrer'\n  {{style textTransform='capitalize'}}\n  data-test-storeknoxInventoryDetails-viewNamespacesLink\n  ...attributes\n>\n  <:leftIcon>\n    <AkIcon\n      @color='primary'\n      @iconName='open-in-new'\n      {{style fontSize='1.5714rem !important'}}\n      data-test-storeknoxInventoryDetails-viewNamespacesLinkOpenInNewIcon\n    />\n  </:leftIcon>\n\n  <:default>\n    {{t 'notificationModule.viewNamespaces'}}\n  </:default>\n</AkLink>","moduleName":"irene/components/storeknox/inventory-details/view-namespace-link/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/view-namespace-link/index.hbs"}});
import Component from '@glimmer/component';

interface StoreknoxInventoryDetailsViewNamespaceLinkSignature {
  Element: HTMLElement;
}

export default class StoreknoxInventoryDetailsViewNamespaceLinkComponent extends Component<StoreknoxInventoryDetailsViewNamespaceLinkSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::ViewNamespaceLink': typeof StoreknoxInventoryDetailsViewNamespaceLinkComponent;
  }
}
