import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck : did-insert}}\n{{#if @open}}\n  <EmberWormhole @to='ak-component-wormhole'>\n    <div ...attributes role='presentation' local-class='drawer-root'>\n      <div\n        data-test-backdrop\n        role='button'\n        aria-hidden='true'\n        local-class='backdrop-root'\n        {{on 'click' this.handleBackdropClick}}\n        {{did-insert this.backdropInserted}}\n      ></div>\n\n      <div\n        data-test-ak-drawer-container\n        local-class='drawer-container drawer-anchor-{{this.anchor}}'\n        {{did-insert this.drawerInserted}}\n      >\n        {{yield (hash closeHandler=this.handleDrawerClose)}}\n      </div>\n    </div>\n  </EmberWormhole>\n{{/if}}", {"contents":"{{! @glint-nocheck : did-insert}}\n{{#if @open}}\n  <EmberWormhole @to='ak-component-wormhole'>\n    <div ...attributes role='presentation' local-class='drawer-root'>\n      <div\n        data-test-backdrop\n        role='button'\n        aria-hidden='true'\n        local-class='backdrop-root'\n        {{on 'click' this.handleBackdropClick}}\n        {{did-insert this.backdropInserted}}\n      ></div>\n\n      <div\n        data-test-ak-drawer-container\n        local-class='drawer-container drawer-anchor-{{this.anchor}}'\n        {{did-insert this.drawerInserted}}\n      >\n        {{yield (hash closeHandler=this.handleDrawerClose)}}\n      </div>\n    </div>\n  </EmberWormhole>\n{{/if}}","moduleName":"irene/components/ak-drawer/index.hbs","parseOptions":{"srcName":"irene/components/ak-drawer/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { runTask } from 'ember-lifeline';

export interface DrawerSignature {
  Element: HTMLDivElement;
  Args: {
    open?: boolean;
    anchor?: 'left' | 'right';
    disableBackdropClick?: boolean;
    onClose: () => void;
  };
  Blocks: { default: [{ closeHandler: () => void }] };
}

export default class DrawerComponent extends Component<DrawerSignature> {
  backdropElement: HTMLElement | null = null;
  drawerElement: HTMLElement | null = null;

  constructor(owner: unknown, args: DrawerSignature['Args']) {
    super(owner, args);

    if (!this.args.onClose) {
      throw new Error('No onClose handler is set');
    }
  }

  get anchor() {
    return this.args.anchor || 'left';
  }

  @action
  handleBackdropClick() {
    if (!this.args.disableBackdropClick) {
      this.handleDrawerClose();
    }
  }

  @action
  handleDrawerClose() {
    runTask(this, () => this.args.onClose(), 300);

    if (this.backdropElement) {
      this.backdropElement.style.opacity = '0';
    }

    if (this.drawerElement) {
      this.drawerElement.style.transform = `translateX(${
        this.args.anchor === 'right' ? '100%' : '-100%'
      })`;
    }
  }

  @action
  backdropInserted(element: HTMLElement) {
    runTask(
      this,
      () => {
        this.backdropElement = element;

        element.style.opacity = '1';
        element.style.transition = 'opacity 225ms ease-in-out 0ms';
      },
      0
    );
  }

  @action
  drawerInserted(element: HTMLElement) {
    runTask(
      this,
      () => {
        this.drawerElement = element;

        element.style.transform = 'none';
        element.style.transition = 'transform 225ms ease-out 0ms';
      },
      0
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkDrawer: typeof DrawerComponent;
  }
}
