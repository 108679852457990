import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='three-bounce-spinner' ...attributes>\n  <div local-class='bounce1'></div>\n  <div local-class='bounce2'></div>\n  <div local-class='bounce3'></div>\n</div>", {"contents":"<div local-class='three-bounce-spinner' ...attributes>\n  <div local-class='bounce1'></div>\n  <div local-class='bounce2'></div>\n  <div local-class='bounce3'></div>\n</div>","moduleName":"irene/components/three-bounce-spinner/index.hbs","parseOptions":{"srcName":"irene/components/three-bounce-spinner/index.hbs"}});
import Component from '@glimmer/component';

interface ThreeBounceSpinnerComponentSignature {
  Element: HTMLElement;
}

export default class ThreeBounceSpinnerComponent extends Component<ThreeBounceSpinnerComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ThreeBounceSpinner: typeof ThreeBounceSpinnerComponent;
  }
}
