import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='container' data-test-notification-empty>\n  <AkSvg::NotificationEmpty />\n\n  <AkTypography local-class='message' @gutterBottom={{true}} @variant='h4'>\n    {{@message}}\n  </AkTypography>\n</div>", {"contents":"<div local-class='container' data-test-notification-empty>\n  <AkSvg::NotificationEmpty />\n\n  <AkTypography local-class='message' @gutterBottom={{true}} @variant='h4'>\n    {{@message}}\n  </AkTypography>\n</div>","moduleName":"irene/components/notifications-page/empty/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/empty/index.hbs"}});
import Component from '@glimmer/component';

interface NotificationsPageEmptyComponentComponentSignature {
  Args: {
    message: string;
  };
}

export default class NotificationsPageEmptyComponent extends Component<NotificationsPageEmptyComponentComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NotificationsPage::Empty': typeof NotificationsPageEmptyComponent;
  }
}
