import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @direction='column'\n  @justifyContent='center'\n  data-test-storeknoxInventoryDetails-unscannedVersionTable-storeVersion\n>\n  {{@skAppVersion.version}}\n</AkStack>", {"contents":"<AkStack\n  @direction='column'\n  @justifyContent='center'\n  data-test-storeknoxInventoryDetails-unscannedVersionTable-storeVersion\n>\n  {{@skAppVersion.version}}\n</AkStack>","moduleName":"irene/components/storeknox/inventory-details/unscanned-version/table/store-version/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/unscanned-version/table/store-version/index.hbs"}});
import Component from '@glimmer/component';
import type SkAppVersionModel from 'irene/models/sk-app-version';

interface StoreknoxInventoryDetailsUnscannedVersionTableStoreVersionSignature {
  Args: {
    skAppVersion: SkAppVersionModel;
  };
}

export default class StoreknoxInventoryDetailsUnscannedVersionTableStoreVersionComponent extends Component<StoreknoxInventoryDetailsUnscannedVersionTableStoreVersionSignature> {}
