import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Storeknox::InventoryDetails::SectionInfo\n  @direction='column'\n  @alignItems='center'\n  @width='full'\n  @spacing='3'\n  class='py-6'\n>\n  <AkSvg::NoAccessImage\n    data-test-storeknoxInventoryDetails-vaFileNotAccessibleIllustration\n  />\n\n  <AkStack @width='full' @direction='column' @alignItems='center' @spacing='1'>\n    <AkTypography\n      @fontWeight='bold'\n      @align='center'\n      data-test-storeknoxInventoryDetails-vaFileNotAccessibleHeaderText\n    >\n      {{t 'storeknox.noAcessToVAResultsHeaderText'}}\n    </AkTypography>\n\n    <AkTypography\n      @align='center'\n      {{style maxWidth='430px'}}\n      data-test-storeknoxInventoryDetails-vaFileNotAccessibleSubText\n    >\n      {{t 'storeknox.noAcessToVAResultsSubtext'}}\n    </AkTypography>\n  </AkStack>\n</Storeknox::InventoryDetails::SectionInfo>", {"contents":"<Storeknox::InventoryDetails::SectionInfo\n  @direction='column'\n  @alignItems='center'\n  @width='full'\n  @spacing='3'\n  class='py-6'\n>\n  <AkSvg::NoAccessImage\n    data-test-storeknoxInventoryDetails-vaFileNotAccessibleIllustration\n  />\n\n  <AkStack @width='full' @direction='column' @alignItems='center' @spacing='1'>\n    <AkTypography\n      @fontWeight='bold'\n      @align='center'\n      data-test-storeknoxInventoryDetails-vaFileNotAccessibleHeaderText\n    >\n      {{t 'storeknox.noAcessToVAResultsHeaderText'}}\n    </AkTypography>\n\n    <AkTypography\n      @align='center'\n      {{style maxWidth='430px'}}\n      data-test-storeknoxInventoryDetails-vaFileNotAccessibleSubText\n    >\n      {{t 'storeknox.noAcessToVAResultsSubtext'}}\n    </AkTypography>\n  </AkStack>\n</Storeknox::InventoryDetails::SectionInfo>","moduleName":"irene/components/storeknox/inventory-details/app-details/va-results/file-inaccessible/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/app-details/va-results/file-inaccessible/index.hbs"}});
import Component from '@glimmer/component';

export default class StoreknoxInventoryDetailsAppDetailsVaResultsFileInaccessibleComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::AppDetails::VaResults::FileInaccessible': typeof StoreknoxInventoryDetailsAppDetailsVaResultsFileInaccessibleComponent;
  }
}
