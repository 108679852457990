import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @direction='column'\n  @spacing='1.5'\n  class='mb-3 py-3'\n  local-class='dast-automation-settings-root'\n  data-test-projectSettings-dastAutomationSettings-root\n>\n  <AkStack @width='full' class='px-3'>\n    <ProjectSettings::DastAutomation::AutomationSettings\n      @profileId={{this.project.activeProfileId}}\n      @project={{this.project}}\n      @featureAvailable={{this.dynamicscanAutomationFeatureAvailable}}\n    />\n  </AkStack>\n</AkStack>", {"contents":"<AkStack\n  @direction='column'\n  @spacing='1.5'\n  class='mb-3 py-3'\n  local-class='dast-automation-settings-root'\n  data-test-projectSettings-dastAutomationSettings-root\n>\n  <AkStack @width='full' class='px-3'>\n    <ProjectSettings::DastAutomation::AutomationSettings\n      @profileId={{this.project.activeProfileId}}\n      @project={{this.project}}\n      @featureAvailable={{this.dynamicscanAutomationFeatureAvailable}}\n    />\n  </AkStack>\n</AkStack>","moduleName":"irene/components/project-settings/dast-automation/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/dast-automation/index.hbs"}});
import Component from '@glimmer/component';
import { service } from '@ember/service';

import type ProjectModel from 'irene/models/project';
import type OrganizationService from 'irene/services/organization';

interface ProjectSettingsDastAutomationSignature {
  Args: {
    project: ProjectModel | null;
  };
}

export default class ProjectSettingsDastAutomationComponent extends Component<ProjectSettingsDastAutomationSignature> {
  @service declare organization: OrganizationService;

  get project() {
    return this.args.project;
  }

  get dynamicscanAutomationFeatureAvailable() {
    return !!this.organization.selected?.features?.dynamicscan_automation;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProjectSettings::DastAutomation': typeof ProjectSettingsDastAutomationComponent;
  }
}
