import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='client'>\n  <Partner::ClientInfo @client={{@client}} />\n</div>", {"contents":"<div local-class='client'>\n  <Partner::ClientInfo @client={{@client}} />\n</div>","moduleName":"irene/components/partner/client-detail/index.hbs","parseOptions":{"srcName":"irene/components/partner/client-detail/index.hbs"}});
import Component from '@glimmer/component';
import type PartnerclientModel from 'irene/models/partner/partnerclient';

export interface PartnerClientDetailComponentSignature {
  Args: {
    client: PartnerclientModel;
  };
}

export default class PartnerClientDetailComponent extends Component<PartnerClientDetailComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Partner::ClientDetail': typeof PartnerClientDetailComponent;
  }
}
