import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AnalysisRisk::Tag\n  @computedRisk={{@computedRisk}}\n  local-class='threshold-risk-label'\n  {{style width='fit-content' minWidth='65px'}}\n  ...attributes\n/>", {"contents":"<AnalysisRisk::Tag\n  @computedRisk={{@computedRisk}}\n  local-class='threshold-risk-label'\n  {{style width='fit-content' minWidth='65px'}}\n  ...attributes\n/>","moduleName":"irene/components/project-settings/integrations/risk-threshold/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/integrations/risk-threshold/index.hbs"}});
import Component from '@glimmer/component';

interface ProjectSettingsIntegrationsRiskThresholdSignature {
  Element: HTMLElement;
  Args: { computedRisk: number };
}

export default class ProjectSettingsIntegrationsRiskThresholdComponent extends Component<ProjectSettingsIntegrationsRiskThresholdSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProjectSettings::Integrations::RiskThreshold': typeof ProjectSettingsIntegrationsRiskThresholdComponent;
  }
}
