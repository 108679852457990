export default {
  "client-info-card": "_client-info-card_hk4y2f",
  "client-info-header": "_client-info-header_hk4y2f",
  "thumbnail": "_thumbnail_hk4y2f",
  "thumbnail-icon": "_thumbnail-icon_hk4y2f",
  "title": "_title_hk4y2f",
  "empty-title": "_empty-title_hk4y2f",
  "table": "_table_hk4y2f",
  "row": "_row_hk4y2f",
  "key": "_key_hk4y2f",
  "value": "_value_hk4y2f",
  "actions": "_actions_hk4y2f",
  "action": "_action_hk4y2f"
};
