import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n  data-test-ak-checkbox-root\n  local-class='ak-checkbox-root {{unless @disabled \"ak-checkbox-hover-focus\"}}'\n>\n  <Input\n    data-test-checkbox\n    ...attributes\n    data-indeterminate='{{@indeterminate}}'\n    local-class='ak-checkbox'\n    @checked={{@checked}}\n    disabled={{@disabled}}\n    readonly={{@readonly}}\n    @type='checkbox'\n    id={{this.id}}\n    {{on 'change' this.onChange}}\n    {{on 'click' this.onClick}}\n  />\n\n  {{#if @indeterminate}}\n    <AkIcon local-class='ak-svg-checkbox-icon' @iconName='mdi:minus-box' />\n  {{else}}\n    <AkIcon\n      data-test-checkbox-icon\n      local-class='ak-svg-checkbox-icon'\n      @iconName={{if\n        @checked\n        'mdi:checkbox-marked'\n        'mdi:checkbox-blank-outline'\n      }}\n    />\n  {{/if}}\n</span>", {"contents":"<span\n  data-test-ak-checkbox-root\n  local-class='ak-checkbox-root {{unless @disabled \"ak-checkbox-hover-focus\"}}'\n>\n  <Input\n    data-test-checkbox\n    ...attributes\n    data-indeterminate='{{@indeterminate}}'\n    local-class='ak-checkbox'\n    @checked={{@checked}}\n    disabled={{@disabled}}\n    readonly={{@readonly}}\n    @type='checkbox'\n    id={{this.id}}\n    {{on 'change' this.onChange}}\n    {{on 'click' this.onClick}}\n  />\n\n  {{#if @indeterminate}}\n    <AkIcon local-class='ak-svg-checkbox-icon' @iconName='mdi:minus-box' />\n  {{else}}\n    <AkIcon\n      data-test-checkbox-icon\n      local-class='ak-svg-checkbox-icon'\n      @iconName={{if\n        @checked\n        'mdi:checkbox-marked'\n        'mdi:checkbox-blank-outline'\n      }}\n    />\n  {{/if}}\n</span>","moduleName":"irene/components/ak-checkbox/index.hbs","parseOptions":{"srcName":"irene/components/ak-checkbox/index.hbs"}});
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';

export interface AkCheckboxSignature {
  Element: HTMLInputElement;
  Args: {
    id?: string;
    indeterminate?: boolean;
    checked?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    onClick?: (event: MouseEvent) => void;
    onChange?: (event: Event, checked: boolean) => void;
  };
}

export default class AkCheckboxComponent extends Component<AkCheckboxSignature> {
  get id() {
    return this.args.id || `ak-checkbox-${guidFor(this)}`;
  }

  @action
  onClick(event: MouseEvent) {
    if (this.args.readonly) {
      event.preventDefault();

      return false;
    }

    if (this.args.onClick) {
      this.args.onClick(event);
    }
  }

  @action
  onChange(event: Event) {
    if (this.args.onChange) {
      this.args.onChange(event, (event.target as HTMLInputElement).checked);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkCheckbox: typeof AkCheckboxComponent;
  }
}
