import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @direction='column' @spacing='2' @width='full'>\n  {{#if @skInventoryApp.monitoringStatusIsPending}}\n    <Storeknox::InventoryDetails::AppDetails::MonitoringPendingInfo\n      @skInventoryApp={{@skInventoryApp}}\n    />\n\n  {{else}}\n    <Storeknox::InventoryDetails::AppDetails::ActionsList\n      @skInventoryApp={{@skInventoryApp}}\n    />\n  {{/if}}\n\n  <Storeknox::InventoryDetails::AppDetails::AppInfo\n    @skInventoryApp={{@skInventoryApp}}\n  />\n\n  <Storeknox::InventoryDetails::AppDetails::VaResults\n    @skInventoryApp={{@skInventoryApp}}\n  />\n</AkStack>", {"contents":"<AkStack @direction='column' @spacing='2' @width='full'>\n  {{#if @skInventoryApp.monitoringStatusIsPending}}\n    <Storeknox::InventoryDetails::AppDetails::MonitoringPendingInfo\n      @skInventoryApp={{@skInventoryApp}}\n    />\n\n  {{else}}\n    <Storeknox::InventoryDetails::AppDetails::ActionsList\n      @skInventoryApp={{@skInventoryApp}}\n    />\n  {{/if}}\n\n  <Storeknox::InventoryDetails::AppDetails::AppInfo\n    @skInventoryApp={{@skInventoryApp}}\n  />\n\n  <Storeknox::InventoryDetails::AppDetails::VaResults\n    @skInventoryApp={{@skInventoryApp}}\n  />\n</AkStack>","moduleName":"irene/components/storeknox/inventory-details/app-details/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/app-details/index.hbs"}});
import Component from '@glimmer/component';
import type SkInventoryAppModel from 'irene/models/sk-inventory-app';

interface StoreknoxInventoryDetailsAppDetailsSignature {
  Args: {
    skInventoryApp: SkInventoryAppModel;
  };
}

export default class StoreknoxInventoryDetailsAppDetailsComponent extends Component<StoreknoxInventoryDetailsAppDetailsSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::AppDetails': typeof StoreknoxInventoryDetailsAppDetailsComponent;
  }
}
