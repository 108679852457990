import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkSkeleton @width='250px' @height='14px' class='mt-2 mb-4' />\n\n<div local-class='summary-header-root' class='mb-3'>\n  <AkStack\n    @alignItems='center'\n    @justifyContent='space-between'\n    @spacing='1.5'\n    local-class='summary-header-container'\n  >\n    <AkStack @alignItems='center'>\n      <AkSkeleton @width='40px' @height='40px' />\n\n      <AkSkeleton @width='250px' @height='18px' class='ml-2' />\n    </AkStack>\n\n    <AkStack @alignItems='center' @spacing='1.5'>\n      <AkSkeleton @width='160px' @height='35px' />\n\n      <AkSkeleton @width='18px' @height='18px' class='ml-3' />\n    </AkStack>\n  </AkStack>\n</div>\n\n<Sbom::EmptyLoadingView\n  @empty={{false}}\n  @loading={{true}}\n  @loadingSvgHeight='200px'\n  @height='500px'\n/>", {"contents":"<AkSkeleton @width='250px' @height='14px' class='mt-2 mb-4' />\n\n<div local-class='summary-header-root' class='mb-3'>\n  <AkStack\n    @alignItems='center'\n    @justifyContent='space-between'\n    @spacing='1.5'\n    local-class='summary-header-container'\n  >\n    <AkStack @alignItems='center'>\n      <AkSkeleton @width='40px' @height='40px' />\n\n      <AkSkeleton @width='250px' @height='18px' class='ml-2' />\n    </AkStack>\n\n    <AkStack @alignItems='center' @spacing='1.5'>\n      <AkSkeleton @width='160px' @height='35px' />\n\n      <AkSkeleton @width='18px' @height='18px' class='ml-3' />\n    </AkStack>\n  </AkStack>\n</div>\n\n<Sbom::EmptyLoadingView\n  @empty={{false}}\n  @loading={{true}}\n  @loadingSvgHeight='200px'\n  @height='500px'\n/>","moduleName":"irene/components/sbom/scan-details/skeleton-loader/index.hbs","parseOptions":{"srcName":"irene/components/sbom/scan-details/skeleton-loader/index.hbs"}});
import Component from '@glimmer/component';

export default class SbomScanDetailsSkeletonLoaderComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::ScanDetails::SkeletonLoader': typeof SbomScanDetailsSkeletonLoaderComponent;
  }
}
