import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @direction='column' @alignItems='center' local-class='empty-container'>\n  <AkSvg::NoPendingItems\n    data-test-storeknoxInventory-pendingReviewEmptyIllustration\n  />\n\n  <AkTypography\n    @variant='h5'\n    @gutterBottom={{true}}\n    local-class='header'\n    data-test-storeknoxInventory-pendingReviewEmptyHeaderText\n  >\n    {{t 'storeknox.noPendingItems'}}\n  </AkTypography>\n\n  <AkTypography\n    @align='center'\n    {{style maxWidth='25em'}}\n    data-test-storeknoxInventory-pendingReviewEmptyDescriptionText\n  >\n    {{t 'storeknox.noPendingItemsDescription'}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack @direction='column' @alignItems='center' local-class='empty-container'>\n  <AkSvg::NoPendingItems\n    data-test-storeknoxInventory-pendingReviewEmptyIllustration\n  />\n\n  <AkTypography\n    @variant='h5'\n    @gutterBottom={{true}}\n    local-class='header'\n    data-test-storeknoxInventory-pendingReviewEmptyHeaderText\n  >\n    {{t 'storeknox.noPendingItems'}}\n  </AkTypography>\n\n  <AkTypography\n    @align='center'\n    {{style maxWidth='25em'}}\n    data-test-storeknoxInventory-pendingReviewEmptyDescriptionText\n  >\n    {{t 'storeknox.noPendingItemsDescription'}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/storeknox/inventory/pending-review/empty/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory/pending-review/empty/index.hbs"}});
import Component from '@glimmer/component';

export default class StoreknoxInventoryPendingReviewEmptyComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Inventory::PendingReview::Empty': typeof StoreknoxInventoryPendingReviewEmptyComponent;
  }
}
