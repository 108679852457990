import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkButton\n  @variant='outlined'\n  @disabled={{@disabled}}\n  @color={{if @needsAction 'primary'}}\n  local-class='action-button-root \n  {{if @needsAction \"needs-action\"}} \n  {{if @featureInProgress \"feature-in-progress\"}}'\n  ...attributes\n>\n  <:leftIcon>\n    <AkIcon\n      @iconName={{if @featureInProgress 'auto-fix-high' 'report'}}\n      @color='inherit'\n      data-test-storeknoxInventoryDetails-actionBtn-leftIcon\n    />\n  </:leftIcon>\n\n  <:default>\n    {{@label}}\n  </:default>\n\n  <:rightIcon>\n    {{#unless @hideRightIcon}}\n      <AkIcon\n        @iconName='arrow-outward'\n        @color='textPrimary'\n        {{style fontSize='1.2857em !important'}}\n        data-test-storeknoxInventoryDetails-actionBtn-rightIcon\n      />\n    {{/unless}}\n  </:rightIcon>\n</AkButton>", {"contents":"<AkButton\n  @variant='outlined'\n  @disabled={{@disabled}}\n  @color={{if @needsAction 'primary'}}\n  local-class='action-button-root \n  {{if @needsAction \"needs-action\"}} \n  {{if @featureInProgress \"feature-in-progress\"}}'\n  ...attributes\n>\n  <:leftIcon>\n    <AkIcon\n      @iconName={{if @featureInProgress 'auto-fix-high' 'report'}}\n      @color='inherit'\n      data-test-storeknoxInventoryDetails-actionBtn-leftIcon\n    />\n  </:leftIcon>\n\n  <:default>\n    {{@label}}\n  </:default>\n\n  <:rightIcon>\n    {{#unless @hideRightIcon}}\n      <AkIcon\n        @iconName='arrow-outward'\n        @color='textPrimary'\n        {{style fontSize='1.2857em !important'}}\n        data-test-storeknoxInventoryDetails-actionBtn-rightIcon\n      />\n    {{/unless}}\n  </:rightIcon>\n</AkButton>","moduleName":"irene/components/storeknox/inventory-details/app-details/actions-list/button/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/app-details/actions-list/button/index.hbs"}});
import Component from '@glimmer/component';

interface StoreknoxInventoryDetailsAppDetailsActionsListButtonSignature {
  Element: HTMLElement;
  Args: {
    needsAction?: boolean;
    featureInProgress?: boolean;
    disabled?: boolean;
    label: string;
    hideRightIcon?: boolean;
  };
}

export default class StoreknoxInventoryDetailsAppDetailsActionsListButtonComponent extends Component<StoreknoxInventoryDetailsAppDetailsActionsListButtonSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::AppDetails::ActionsList::Button': typeof StoreknoxInventoryDetailsAppDetailsActionsListButtonComponent;
  }
}
