import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='0.5'>\n  <AkTypography @tag='span'>\n    {{@deviceProps.deviceIdentifier}}\n  </AkTypography>\n\n  {{#if @deviceProps.customIdentifier}}\n    <AkTypography @tag='span' @color='textSecondary'>\n      ({{@deviceProps.customIdentifier}})\n    </AkTypography>\n  {{/if}}\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='0.5'>\n  <AkTypography @tag='span'>\n    {{@deviceProps.deviceIdentifier}}\n  </AkTypography>\n\n  {{#if @deviceProps.customIdentifier}}\n    <AkTypography @tag='span' @color='textSecondary'>\n      ({{@deviceProps.customIdentifier}})\n    </AkTypography>\n  {{/if}}\n</AkStack>","moduleName":"irene/components/file-details/dynamic-scan/action/drawer/device-pref-table/device-id/index.hbs","parseOptions":{"srcName":"irene/components/file-details/dynamic-scan/action/drawer/device-pref-table/device-id/index.hbs"}});
import Component from '@glimmer/component';
import type AvailableManualDeviceModel from 'irene/models/available-manual-device';

export interface FileDetailsDynamicScanDrawerDevicePrefTableDeviceIdSignature {
  Args: {
    deviceProps: AvailableManualDeviceModel;
  };
}

export default class FileDetailsDynamicScanDrawerDevicePrefTableDeviceIdComponent extends Component<FileDetailsDynamicScanDrawerDevicePrefTableDeviceIdSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'file-details/dynamic-scan/action/drawer/device-pref-table/device-id': typeof FileDetailsDynamicScanDrawerDevicePrefTableDeviceIdComponent;
  }
}
