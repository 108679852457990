export default {
  "file-overview-file-details": "_file-overview-file-details_uxcqn6",
  "file-overview-versions-tags": "_file-overview-versions-tags_uxcqn6",
  "file-version-container": "_file-version-container_uxcqn6",
  "file-version-title": "_file-version-title_uxcqn6",
  "file-tags-container": "_file-tags-container_uxcqn6",
  "store-logo-container": "_store-logo-container_uxcqn6",
  "appstore-logo-vector": "_appstore-logo-vector_uxcqn6",
  "file-overview-details": "_file-overview-details_uxcqn6"
};
