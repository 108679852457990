import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showKnowledgeBase}}\n  <AkButton\n    class={{@triggerBtnClass}}\n    {{on 'click' this.onOpenKnowledgeBase}}\n    data-test-topNav-KnowledgeBaseBtn\n  >\n    <:leftIcon>\n      <AkIcon @iconName='import-contacts' />\n    </:leftIcon>\n\n    <:default>{{t 'knowledgeBase'}}</:default>\n  </AkButton>\n{{/if}}", {"contents":"{{#if this.showKnowledgeBase}}\n  <AkButton\n    class={{@triggerBtnClass}}\n    {{on 'click' this.onOpenKnowledgeBase}}\n    data-test-topNav-KnowledgeBaseBtn\n  >\n    <:leftIcon>\n      <AkIcon @iconName='import-contacts' />\n    </:leftIcon>\n\n    <:default>{{t 'knowledgeBase'}}</:default>\n  </AkButton>\n{{/if}}","moduleName":"irene/components/top-nav/knowledge-base/index.hbs","parseOptions":{"srcName":"irene/components/top-nav/knowledge-base/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type FreshdeskService from 'irene/services/freshdesk';

interface TopNavKnowledgeBaseSignature {
  Args: { triggerBtnClass?: string };
}

export default class TopNavKnowledgeBaseComponent extends Component<TopNavKnowledgeBaseSignature> {
  @service declare freshdesk: FreshdeskService;

  get showKnowledgeBase() {
    return this.freshdesk.supportWidgetIsEnabled;
  }

  @action
  onOpenKnowledgeBase() {
    this.freshdesk.openSupportWidget();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TopNav::KnowledgeBase': typeof TopNavKnowledgeBaseComponent;
  }
}
