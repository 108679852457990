import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' data-test-platform='{{@project.platformIcon}}'>\n  <AppPlatformIcon @platform='{{@project.platformIcon}}' />\n</AkStack>\n\n<div\n  local-class='package-name'\n  title='Project ID: {{@project.id}}'\n  data-test-package_name\n>\n  {{@project.packageName}}\n</div>\n\n<AkTypography @color='textSecondary' @fontWeight='light' data-test-created_on>\n  {{day-js date=@project.createdOn format='DD MMM YYYY'}}\n</AkTypography>\n\n{{#if (and @enableViewFiles @clientId @project.id)}}\n  <AkLink\n    @route='authenticated.partner.project'\n    @models={{array @clientId @project.id}}\n    @underline='none'\n    @color='primary'\n    local-class='view-files-button'\n    data-test-view-files\n  >\n    {{t 'viewUploads'}}\n  </AkLink>\n{{/if}}", {"contents":"<AkStack @alignItems='center' data-test-platform='{{@project.platformIcon}}'>\n  <AppPlatformIcon @platform='{{@project.platformIcon}}' />\n</AkStack>\n\n<div\n  local-class='package-name'\n  title='Project ID: {{@project.id}}'\n  data-test-package_name\n>\n  {{@project.packageName}}\n</div>\n\n<AkTypography @color='textSecondary' @fontWeight='light' data-test-created_on>\n  {{day-js date=@project.createdOn format='DD MMM YYYY'}}\n</AkTypography>\n\n{{#if (and @enableViewFiles @clientId @project.id)}}\n  <AkLink\n    @route='authenticated.partner.project'\n    @models={{array @clientId @project.id}}\n    @underline='none'\n    @color='primary'\n    local-class='view-files-button'\n    data-test-view-files\n  >\n    {{t 'viewUploads'}}\n  </AkLink>\n{{/if}}","moduleName":"irene/components/partner/client-project-list/item/index.hbs","parseOptions":{"srcName":"irene/components/partner/client-project-list/item/index.hbs"}});
import Component from '@glimmer/component';
import type PartnerPartnerclientProjectModel from 'irene/models/partner/partnerclient-project';

interface PartnerClientProjectListItemSignature {
  Args: {
    project: PartnerPartnerclientProjectModel;
    clientId: string;
    enableViewFiles: boolean;
  };
}

export default class PartnerClientProjectListItemComponent extends Component<PartnerClientProjectListItemSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Partner::ClientProjectList::Item': typeof PartnerClientProjectListItemComponent;
  }
}
